import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSiteSearch } from './SiteSearch'
import SiteSearchInput from './SiteSearchInput'

const ROUTE_CHANGE_EVENT = 'routeChangeComplete'
const HASH_CHANGE_EVENT = 'hashChangeComplete'

const useSiteSearchDrawer = () => {
  const router = useRouter()

  const {
    isOpen: isSiteSearchOpen,
    onOpen: handleOpenSiteSearch,
    onClose: handleCloseSiteSearch,
  } = useSiteSearch()

  useEffect(() => {
    router.events.on(ROUTE_CHANGE_EVENT, handleCloseSiteSearch)
    router.events.on(HASH_CHANGE_EVENT, handleCloseSiteSearch)

    return () => {
      router.events.off(ROUTE_CHANGE_EVENT, handleCloseSiteSearch),
        router.events.off(HASH_CHANGE_EVENT, handleCloseSiteSearch)
    }
  }, [router.events, handleCloseSiteSearch, isSiteSearchOpen])

  return {
    isSiteSearchOpen,
    handleOpenSiteSearch,
    handleCloseSiteSearch,
  }
}

const SiteSearchDrawer = ({
  isSearchOpen,
  handleCloseSearch,
}: {
  isSearchOpen: boolean
  handleCloseSearch: () => void
}) => (
  <Drawer placement="top" onClose={handleCloseSearch} isOpen={isSearchOpen} size="full">
    <DrawerOverlay backgroundColor="rgba(7, 25, 55, 0.8)" />
    <DrawerContent
      backgroundColor="deepblue.500"
      height="fit-content"
      padding={{ base: '8px 23px 58px', lg: '18px 58px 58px' }}
    >
      <Box
        maxW="container.xl"
        w="100%"
        m="auto"
        color="white"
        display="flex"
        flexDir={{ base: 'column-reverse', lg: 'row' }}
      >
        <DrawerBody
          sx={{
            'form[role="search"] > .chakra-stack': {
              marginBottom: 0,
            },
            '.chakra-stack > .chakra-input__group': {
              marginTop: 0,
            },
          }}
          maxW={{ lg: 'container.sm' }}
          w="100%"
          ml="auto"
          flex={1}
          padding={0}
          overflow="visible"
        >
          <SiteSearchInput />
        </DrawerBody>
        <DrawerCloseButton
          pos="relative"
          marginInlineStart="auto"
          marginInlineEnd="end"
          mb={{ base: 6, lg: 0 }}
          top="unset"
          right="unset"
          borderRadius="full"
          border="none"
          boxSize={{ base: '40px', lg: '60px' }}
          sx={{
            '> svg': {
              fontSize: '17px',
            },
            ':hover': { background: 'rgba(255, 255, 255, 0.1)' },
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          aria-label="close site search"
          color="white"
          bg="rgba(255, 255, 255, 0.1)"
        />
      </Box>
    </DrawerContent>
  </Drawer>
)

export default SiteSearchDrawer
export { useSiteSearchDrawer }
