import { FinderResults } from 'ui'
import SiteSearchHits from './SiteSearchHits'

const SiteSearchResults = ({ nbHits, query }: { query: string; nbHits: number }) => {
  return (
    <FinderResults.Results>
      {Boolean(nbHits) ? (
        <FinderResults.SiteSearchResultsContainer>
          <SiteSearchHits />
        </FinderResults.SiteSearchResultsContainer>
      ) : (
        <FinderResults.NoResults query={query} />
      )}
      <FinderResults.Footer />
    </FinderResults.Results>
  )
}

export default SiteSearchResults
