import { CONTENT_SERVICE } from 'src/constants'
import {
  Header as CommonHeader,
  NavButton,
  NavDrawer,
  NavigationProvider,
  NavWrapper,
  useHeaderDrawer,
} from 'ui'
import SiteSearchButton from '../Search/SiteSearch/SiteSearchButton'
import SiteSearchDrawer, { useSiteSearchDrawer } from '../Search/SiteSearch/SiteSearchDrawer'

function RightHandContent({ preview }) {
  const { handleCloseNav, size, isOpen: isNavOpen, handleOpenMainNav } = useHeaderDrawer()
  const { isSiteSearchOpen, handleOpenSiteSearch, handleCloseSiteSearch } = useSiteSearchDrawer()

  return (
    <>
      <SiteSearchButton
        handleOpenSiteSearch={() => {
          if (isNavOpen) {
            handleCloseNav(false)
          }
          handleOpenSiteSearch()
        }}
      />
      <SiteSearchDrawer isSearchOpen={isSiteSearchOpen} handleCloseSearch={handleCloseSiteSearch} />
      <NavButton
        isOpen={isNavOpen}
        handleCloseNav={handleCloseNav}
        handleOpenMainNav={handleOpenMainNav}
      />
      <NavDrawer
        handleCloseNav={handleCloseNav}
        isOpen={isNavOpen}
        size={size}
        preview={preview}
        codeId={CONTENT_SERVICE.HEADER_CODE_ID}
      />
    </>
  )
}

function Header({ preview }: { preview: boolean }) {
  return (
    <CommonHeader
      rightHandContent={
        <NavWrapper>
          <NavigationProvider>
            <RightHandContent preview={preview} />
          </NavigationProvider>
        </NavWrapper>
      }
    />
  )
}

export default Header
