import { Box, Flex, GridItem, Text } from '@chakra-ui/react'
import {
  NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
  useGetNavigationElementsForNaumaiFooterQuery,
} from 'content-service'
import { NZGovtLogo, VerticalThinkNewNZLogo } from 'icons'
import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import {
  ContainerWrapper,
  CtaButton,
  FooterDivider,
  FooterSectionLegalLinks,
  FooterSectionLinks,
  FooterSectionSocialLinks,
  GridLayout,
  Link,
} from 'ui'
import { useMediaQuery } from 'utils'
import { createLinks } from 'utils/src/helpers/nav'
import { slugify } from 'utils/src/helpers/slug'

interface CreateFooterSection {
  title: string | undefined
  customUrl?: string
  links: Pick<
    NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
    'items'
  > | null
  useTitle?: boolean
}

interface FooterProps {
  preview?: boolean
}

const createFooterSection = ({
  title = '',
  customUrl,
  links,
  useTitle = false,
}: CreateFooterSection) => {
  return {
    title,
    links: createLinks({
      linkItems: links,
      ...(useTitle && { parentLink: customUrl ?? `/${slugify(title ?? '')}` }),
    }),
  }
}

const Footer = ({ preview = false }: FooterProps): ReactElement | null => {
  const isDesktop = useMediaQuery('(min-width: 992px)')
  const { locale } = useRouter()

  const { data } = useGetNavigationElementsForNaumaiFooterQuery({
    variables: {
      preview,
      locale,
    },
  })
  const { footer, socialLinks, legal, cta } = data || {}

  const footerSectionLinks =
    footer?.items[0]?.navigationElementsCollection?.items.map((item) =>
      createFooterSection({
        title: item?.title ?? '',
        customUrl: item?.customUrl ?? undefined,
        links: item?.navigationElementsCollection ?? null,
        useTitle: true,
      })
    ) ?? []

  const socialLinksSection = createFooterSection({
    title: '',
    links: socialLinks?.items[0]?.navigationElementsCollection || null,
  })

  const legalSection = createFooterSection({
    title: '',
    links: legal?.items[0]?.navigationElementsCollection || null,
  })

  const footerCtaSection = createFooterSection({
    title: '',
    links: cta?.items[0]?.navigationElementsCollection || null,
  })
  const footerCta = footerCtaSection?.links[0]

  const currentYear = `${new Date().getFullYear()}`

  return (
    <Box
      as="footer"
      bg="deepblue.500"
      paddingTop={{ base: 14, lg: 16 }}
      paddingBottom={{ base: 32, lg: 16 }}
      dir="ltr"
    >
      <ContainerWrapper sx={{ py: 0 }}>
        <GridLayout
          mt={0}
          mb={{ base: 0, lg: '62px' }}
          templateColumns={{ base: 'repeat(1fr)', lg: 'repeat(12, 1fr)' }}
        >
          <GridItem colSpan={{ base: 12, lg: 3, xl: 2 }}>
            <Link href="/" title="Link to homepage" aria-label="Link to homepage" display="block">
              <VerticalThinkNewNZLogo w={{ base: 28, xs: 164 }} h={{ base: 10, xs: 16 }} mb={8} />
            </Link>
            {isDesktop && (
              <FooterSectionSocialLinks
                title={socialLinksSection.title}
                links={socialLinksSection.links}
                socialLinkDark={true}
              />
            )}
          </GridItem>

          {footerSectionLinks.map((section, i) => (
            <GridItem
              key={i}
              colSpan={{ base: 12, lg: 3, xl: 2 }}
              colStart={{ lg: i === 3 ? 4 : i == 4 ? 7 : 0 }}
            >
              <FooterSectionLinks
                title={section.title}
                links={section.links}
                fontSize={{ base: '18px' }}
                lineHeight={{ base: 6, md: 5 }}
              />
            </GridItem>
          ))}

          {!isDesktop && (
            <GridItem
              colSpan={{ base: 12, lg: 3, xl: 2 }}
              colStart={{ xl: 1 }}
              sx={{
                // actually rtl
                'html[lang=ar] &': {
                  direction: 'ltr',
                },
              }}
            >
              <FooterSectionSocialLinks
                title={socialLinksSection.title}
                links={socialLinksSection.links}
                socialLinkDark={true}
              />
            </GridItem>
          )}
        </GridLayout>
        <FooterDivider />
        <Flex flexWrap={{ base: 'wrap', md: 'nowrap' }} justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Link
              href="https://www.govt.nz/"
              title="Link to New Zealand Government"
              aria-label="Link to New Zealand Government"
              display="block"
              isExternal
            >
              <NZGovtLogo />
            </Link>
          </Box>
          {footerCta && footerCta.slug && (
            <Box mt={{ base: 6, sm: '23px' }} mb={{ base: 3, sm: '35px' }}>
              <CtaButton
                href={footerCta.slug}
                fontWeight="500"
                fontSize="14px"
                lineHeight="20px"
                height="auto"
                padding="12px 20px"
                background="none"
                color="white"
                borderColor="white"
                borderRadius="32px"
                boxSizing="border-box"
                _hover={{ bg: 'white', color: 'deepWaterBlue' }}
              >
                {footerCta.title}
              </CtaButton>
            </Box>
          )}
        </Flex>
        <FooterDivider />
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent={{ lg: 'space-between' }}
          mt={{ base: 8, md: '27px' }}
        >
          <Text
            color="white"
            opacity={{ base: 0.75, lg: 0.9 }}
            fontSize="sm"
            lineHeight={{ base: '20px', lg: '24px' }}
            mb={{ base: 4, lg: 0 }}
          >
            All content &copy; Education New Zealand {currentYear}
          </Text>
          <FooterSectionLegalLinks
            links={legalSection.links}
            opacity={{ base: 0.75, lg: 0.9 }}
            lineHeight="20px"
            mb={{ base: '16px !important', lg: 0 }}
          />
        </Flex>
      </ContainerWrapper>
    </Box>
  )
}

export default Footer
