import { Box, Text, useDisclosure } from '@chakra-ui/react'
import { useGetNavigationElementsByCodeIdQuery } from 'content-service'
import { createContext, ReactNode, useContext } from 'react'
import { connectSearchBox } from 'react-instantsearch-core'
import { ALGOLIA_INDICES } from 'src/constants'
import { BaseResult, FinderResults, H2, InstantSearchWrapper } from 'ui'
import { createConfig, createURL } from 'utils/src/helpers/algoliaSearch'
import { createLinks } from 'utils/src/helpers/nav'
import SiteSearchResults from './SiteSearchResults'

const { indexName, searchClient } = createConfig({
  indexName: ALGOLIA_INDICES.SITE.MAIN,
})

interface SiteSearchContextInterface {
  isOpen: boolean
  onOpen(): void
  onClose(): void
  quickLinks: BaseResult[]
}

const SiteSearchContext = createContext<SiteSearchContextInterface | null>(null)

export function useSiteSearch() {
  const context = useContext(SiteSearchContext)
  if (!context) {
    throw new Error('useSiteSearch must be defined within a SiteSearchProvider')
  }
  return context
}

interface SiteSearchProviderProps {
  children: ReactNode | ReactNode[]
  preview: boolean
}

const VirtualSearchBox = connectSearchBox(() => null)

export function SiteSearchProvider({ children, preview }: SiteSearchProviderProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data } = useGetNavigationElementsByCodeIdQuery({
    variables: { codeId: 'naumai-quick-links', preview, locale: 'en' },
  })

  const quickLinks =
    createLinks({
      linkItems: data?.swnz_navigationCollection?.items[0]?.navigationElementsCollection,
    }).map((link) => ({
      label: link.title,
      url: link.slug,
      objectID: link.id,
    })) ?? []

  return (
    <SiteSearchContext.Provider value={{ onClose, onOpen, isOpen, quickLinks }}>
      <InstantSearchWrapper
        indexName={indexName}
        searchClient={searchClient}
        searchState={{}}
        createURL={createURL}
      >
        <VirtualSearchBox />
        {children}
      </InstantSearchWrapper>
    </SiteSearchContext.Provider>
  )
}

const SiteSearch = () => {
  return (
    <FinderResults>
      <FinderResults.Body>
        {({ searchResults }) => {
          const { nbHits, query } = searchResults || {}
          return (
            <Box mt={{ base: 10, lg: 20 }}>
              {nbHits > 0 ? (
                <FinderResults.SiteSearchResultsContainer>
                  <H2 mb={{ base: 4, lg: 5 }} lineHeight={{ base: '44px', lg: '56px' }}>
                    Search results for ‘{query}’
                  </H2>
                  <FinderResults.ResultsBar borderBottomWidth="0px" pb={0} mb={0}>
                    <Text
                      fontSize={{ base: 14, lg: 18 }}
                      fontWeight="500"
                      letterSpacing="-1px"
                      lineHeight={{ base: '20px', lg: '24px' }}
                      color="deepblue.300"
                      mb={{ base: '20px', lg: '36px' }}
                    >
                      <span>{nbHits} results found:</span>
                    </Text>
                  </FinderResults.ResultsBar>
                </FinderResults.SiteSearchResultsContainer>
              ) : null}
              <SiteSearchResults nbHits={nbHits} query={query} />
            </Box>
          )
        }}
      </FinderResults.Body>
    </FinderResults>
  )
}

export default SiteSearch
