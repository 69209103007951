import { Box } from '@chakra-ui/react'
import { SearchAutocomplete } from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { createConfig } from 'utils/src/helpers/algoliaSearch'
import { ALGOLIA_INDICES, ROUTES } from '../../../constants'
import { useSiteSearch } from './SiteSearch'

const { indexName, searchClient } = createConfig({
  indexName: ALGOLIA_INDICES.SITE.MAIN,
})

const searchQuery = searchPlugins.createSiteSearchQuery({
  searchClient,
  indexName,
  defaultLabel: ALGOLIA_INDICES.EMPTY_RECORD_TITLE,
})

const SiteSearchInput = () => {
  const { quickLinks } = useSiteSearch()
  const naumaiQuickLinks = searchPlugins.naumaiQuickLinksPlugin(quickLinks)

  return (
    <Box
      sx={{
        input: {
          color: 'rgba(255, 255, 255, 0.7)',
          margin: '2px',
        },
        'input, input:hover, input[data-hover], input:focus, .chakra-input__right-addon': {
          background: 'rgba(255, 255, 255, 0.1)',
        },
        'input:hover, input[data-hover], input:hover::placeholder, input:focus': {
          color: 'white',
        },
        'input:focus': {
          boxShadow: 'var(--chakra-shadows-outline)',
          border: 'none',
        },
        '.chakra-input__right-addon': { marginInlineStart: 0 },
      }}
    >
      <SearchAutocomplete
        title="Search NauMai NZ"
        placeholder="Search NauMai NZ"
        facet="hierarchy.l1"
        emptyQueryDropdownTitle="Quick links"
        resultsPath={ROUTES.SITE_SEARCH}
        plugins={[naumaiQuickLinks, searchQuery]}
        showLabel={false}
        shouldRedirectOnSubmit={true}
        displayInModal={false}
        autocompletePanelProps={{
          titleProps: {
            mb: '10px',
            fontSize: { base: '20px', lg: '22px' },
            lineHeight: { base: '30px', lg: 9 },
          },
          contentWrapperProps: {
            position: 'unset',
            mt: 8,
            mb: 0,
            p: 0,
            backgroundColor: 'unset',
            boxShadow: 'none',
            borderRadius: 'none',
            width: '100%',
            top: 'unset',
            left: 'unset',
            textAlign: 'left',
            color: 'white',
          },
        }}
        autocompleteItem={{
          itemProps: {
            mb: '10px',
          },
          linkProps: {
            color: 'white',
            _hover: {
              color: 'teal.500',
            },
            background: 'inherit',
            boxShadow: 'unset',
          },
          highlightProps: {
            my: 0,
          },
        }}
      />
    </Box>
  )
}

export default SiteSearchInput
