
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { ApolloProvider } from '@apollo/client';
import { useApollo } from 'content-service';
import type { AppProps as DefaultAppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { ErrorProps } from 'next/error';
import Script from 'next/script';
// third party css has to be imported directly into _app
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-phone-input-2/lib/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { SiteSearchProvider } from 'src/components/Search/SiteSearch/SiteSearch';
import GlobalLayout from 'src/layouts/GlobalLayout';
import { TealiumProvider } from 'tracking';
import { StyleProviderWrapper, Tohu } from 'ui';
type AppProps = DefaultAppProps & {
    pageProps: {
        preview: boolean;
    };
    err: ErrorProps;
};
const LeadCaptureFormPopup = dynamic(() => import('../components/forms/LeadCaptureFormPopup'), {
    ssr: false
});
function App({ Component, pageProps, err }: AppProps) {
    const apolloClient = useApollo(pageProps);
    const { preview } = pageProps;
    return (<>
      <Script id="naumai-utag-init-script" strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
              window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
              window.utag_cfg_ovrd.noview = true;
              (function(a,b,c,d){
                a="${process.env.NEXT_PUBLIC_TEALIUM_URL ?? ''}";
                b=document;c='script';d=b.createElement(c);
                d.onload=function() { b.dispatchEvent(new Event("utagLoaded")); };
                d.src=a;d.type='text/java'+c;d.async=true;
                a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)
              })();
            `
        }}/>
      <ApolloProvider client={apolloClient}>
        <SiteSearchProvider preview={preview}>
          <TealiumProvider>
            <StyleProviderWrapper>
              <GlobalLayout preview={preview}>
                <Component {...pageProps} err={err}/>
                <Tohu />
                <LeadCaptureFormPopup />
              </GlobalLayout>
            </StyleProviderWrapper>
          </TealiumProvider>
        </SiteSearchProvider>
      </ApolloProvider>
    </>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  