import { ALGOLIA_INDICES } from 'src/constants'
import { SiteSearchHit } from '../Search/SiteSearch/SiteSearchHits'

export const determineSiteSearchHitTitle = (hit: SiteSearchHit) => {
  if (hit.url === '/' || (!hit.hierarchy.l1 && !hit.hierarchy.l0)) {
    return ALGOLIA_INDICES.EMPTY_RECORD_TITLE
  }

  if (hit.hierarchy.l1) {
    return hit.hierarchy.l1
  }

  return hit.hierarchy.l0
}
