import { Box } from '@chakra-ui/react'
import { SearchIcon } from 'icons'
import { CtaButton } from 'ui'

const SiteSearchButton = ({ handleOpenSiteSearch }: { handleOpenSiteSearch: () => void }) => (
  <CtaButton
    borderRadius="full"
    border="none"
    boxSize={10}
    marginInlineEnd={{ base: '10px', lg: '40px' }}
    display="flex"
    justifyContent="center"
    alignItems="center"
    aria-label="open site search"
    bg="transparent"
    icon={
      <Box mt="2px" w="1em" h="1em" fontSize="30px">
        <SearchIcon />
      </Box>
    }
    onClick={handleOpenSiteSearch}
    _hover={{
      cursor: 'pointer',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    }}
  >
    <></>
  </CtaButton>
)

export default SiteSearchButton
